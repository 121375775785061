import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-agb',
  templateUrl: './agb.component.html',
  styleUrls: ['./agb.component.css'],
})
export class AgbComponent implements OnInit {
  
  title = 'Phree - Allgemeine Geschäftsbedingungen';
  baseUrl = environment.apiUrl;
  myTemplate: any = '';

  constructor(private http: HttpClient, private titleService: Title) {
      http.get(this.baseUrl + 'agb', {responseType: 'text'}).subscribe(response => {
        this.myTemplate = response;
      });
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }
}
