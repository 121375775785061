import { Sex } from './sex';

export class UserRegisterDto {
  Firstname: String = '';
  Surname: String = '';
  Sex: Sex = Sex.Unknown;
  DateOfBirth: Date;
  PhoneE164: String;
  Password: String;
  LanguageStr: String = 'de-CH';
}
