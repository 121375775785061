import { Address } from './address';

export class Powerhost {
  id: number;
  latitude: number;
  longitude: number;
  label: string;
  address: Address;
  powerHostStatus: Powerhoststatus;
}

export enum Powerhoststatus {
  ok,
  noBorrow,
  noReturn,
  noBorrowNoReturn
}
