import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LogService } from '../_services/log.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '../_services/translate.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  title = 'Phree - ';
  lat: Number = 47.402;
  lng: Number = 8.545214;

  constructor(private router: Router, private logger: LogService, private translate: TranslateService, private titleService: Title) {
    // make bookmarks work
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          const tree = router.parseUrl(router.url);
          if (tree.fragment) {
            const element = document.querySelector('#' + tree.fragment);
            if (element) { element.scrollIntoView(); }
          }
       }
    });
   }

  public icon = {
    url: '/../../assets/images/marker_red.png',
    scaledSize: {
      height: 40,
      width: 28
    }
  };

  ngOnInit() {
    this.logger.logEntry('ABOUT');
    this.titleService.setTitle(this.title + this.translate.translateSingle('About Us'));
  }
}
