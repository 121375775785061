import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.css']
})
export class PrivacyComponent implements OnInit {
  baseUrl = environment.apiUrl;
  myTemplate: any = '';
  title = 'Phree - Datenschutzrichtlinien';

  constructor(private http: HttpClient, private titleService: Title) {
    http.get(this.baseUrl + 'privacy', {responseType: 'text'}).subscribe(response => {
      this.myTemplate = response;
    });
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

}
