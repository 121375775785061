import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LogService } from '../_services/log.service';

@Component({
  selector: 'app-user-experience',
  templateUrl: './user-experience.component.html',
  styleUrls: ['./user-experience.component.css']
})
export class UserExperienceComponent implements OnInit {
  @ViewChild('videoPlayer') videoplayer: ElementRef;
  playing = false;
  
  title = 'Phree - User Experience';
  constructor(private logger: LogService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.logger.logEntry('User-Experience');

    this.meta.updateTag({property: 'og:site_name', content: 'Phree - User Experience'});
    this.meta.updateTag({property: 'og:title', content: 'Phree - Experience'});
    this.meta.updateTag({property: 'og:url', content: 'https://phree.ch'});
    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({property: 'og:image', content: 'https://phree.ch/assets/images/ogimageUserExperience.png'});
    this.meta.updateTag({property: 'og:description', content: 'No more running out of battery!'});
  }

  toggleVideo(event: any) {
    if(this.playing) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  }

  pauseVideo() {
    this.videoplayer.nativeElement.pause();
    this.playing = false;
  }

  playVideo() {
    this.videoplayer.nativeElement.play();
    this.playing = true;
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.videoplayer.nativeElement.offsetTop;
    const height = this.videoplayer.nativeElement.offsetHeight;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition >= componentPosition + height) {
      this.pauseVideo();
    } else {
      this.playVideo();
    }
  }
}
