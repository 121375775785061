import { Component, HostBinding } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from './_services/translate.service';
import { BsLocaleService } from 'ngx-bootstrap';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { deLocale, enGbLocale, frLocale, itLocale } from 'ngx-bootstrap/locale';
// https://angular.io/guide/animations#animation-api-summary
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { Router, NavigationEnd } from '@angular/router';

declare let gtag: Function;

// Decorator
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    // slideInAnimation
    // animation triggers go here
  ]
})
export class AppComponent {
  lang: string;
  public constructor(private titleService: Title, private translate: TranslateService, public router: Router) {
    this.setTitle('Phree - Mobile Energy');

    // initialize locales
    defineLocale('en', enGbLocale);
    defineLocale('de', deLocale);
    defineLocale('fr', frLocale);
    defineLocale('it', itLocale);

    this.lang = 'de-CH';

    if (navigator.language.includes('en')) {
      this.lang = 'en-GB';
    }
    if (navigator.language.includes('de')) {
      this.lang = 'de-CH';
    }
    if (navigator.language.includes('fr')) {
      this.lang = 'fr-CH';
    }
    if (navigator.language.includes('it')) {
      this.lang = 'it-CH';
    }

    const languageFromStorage = localStorage.getItem('language');
    if (languageFromStorage != null) {
      this.lang = languageFromStorage;
    }

    // make sure language is set on startup;
    translate.save(this.lang);
    translate.use(this.lang);

    this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
            gtag('config', 'UA-147991342-1', 
                  {
                    'page_path': event.urlAfterRedirects
                  }
                );
        }
      })
  }

  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
