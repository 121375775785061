export class ISO3166Country {

    name: string;
    alpha2: string;
    alpha3: string;
    numericCode: number;
    dialCodes: string[];

    constructor(name:string, alpha2:string, alpha3:string, numericCode:number, dialCodes?:string[]){
        this.name = name;
        this.alpha2 = alpha2;
        this.alpha3 = alpha3;
        this.numericCode = numericCode;
        this.dialCodes = dialCodes;
    }

}