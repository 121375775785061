import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AlertifyService } from '../_services/alertify.service';
import { MessengerService } from '../_services/messenger.service';
import { Message } from '../_models/message';
import { LogService } from '../_services/log.service';
import { TranslateService } from '../_services/translate.service';

import { MatExpansionModule } from '@angular/material';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css'],
})
export class SupportComponent implements OnInit {

  message: Message;
  sendMessageForm: FormGroup;

  title = 'Phree - ';


  constructor(private router: Router, private alertify: AlertifyService,
    private fb: FormBuilder, private messenger: MessengerService,
    private route: ActivatedRoute, private logger: LogService,
    private translate: TranslateService,
    private titleService: Title) { }

  ngOnInit() {
    this.createSendMessageForm();
    this.logger.logEntry('Support');
    this.titleService.setTitle(this.title + this.translate.translateSingle('Support'));
  }

  createSendMessageForm() {
    this.sendMessageForm = this.fb.group({
      fromname: ['', Validators.required],
      fromemail: ['', Validators.required],
      message: ['', Validators.required]
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: FormGroup) {
    return null;
    // return g.get('password').value === g.get('confirmPassword').value ? null : {'mismatch': true};
  }

  sendMessage() {
    if (this.sendMessageForm.valid) {
      this.message = Object.assign({}, this.sendMessageForm.value);
      this.messenger.sendMessage(this.message).subscribe(next => {
        this.alertify.message(this.translate.translateSingle('Thanks for contacting us!'));
      }, error => {
        if (error.status === 501) {
          this.alertify.message(this.translate.translateSingle('Message could not be sent. Please try again later.'));
        }
      });
    }
  }

}
