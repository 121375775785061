import { Component, OnInit } from '@angular/core';
import { LogService } from '../_services/log.service';

@Component({
  selector: 'app-sbb',
  templateUrl: './sbb.component.html',
  styleUrls: ['./sbb.component.css']
})
export class SbbComponent implements OnInit {

  constructor(private logger: LogService) { }

  ngOnInit() {
    this.logger.logEntry('SBB');
  }

}
