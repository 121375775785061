import { Component, OnInit } from '@angular/core';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '../_services/translate.service';

@Component({
  selector: 'app-setnewpassword',
  templateUrl: './setNewPassword.component.html',
  styleUrls: ['./setNewPassword.component.css']
})
export class SetNewPasswordComponent implements OnInit {
  model: any = {};
  resetPasswordForm: FormGroup;
  buttonJustPressed: Boolean = false;
  passwordRequirements: String = '';

  constructor(private authService: AuthService, private alertify: AlertifyService,
    private fb: FormBuilder, private router: Router, private route: ActivatedRoute,
     private translate: TranslateService) { }

  ngOnInit() {
    this.authService.getPasswordrequirements().subscribe(response => {
      this.passwordRequirements = response;
    }, error => {
      console.log('Error getting passwordrequirements: ');
      console.log(error);
    });
    this.createForm();
  }

  createForm() {
    this.resetPasswordForm = this.fb.group({
      newpassword: ['', Validators.required],
    }, {validator: this.validator});
  }

  validator(g: FormGroup) {
    return null;
    // return g.get('password').value === g.get('confirmPassword').value ? null : {'mismatch': true};
  }

  setNewPassword() {
    this.buttonJustPressed = true;
    if (this.resetPasswordForm.valid) {
      this.model = Object.assign({}, this.resetPasswordForm.value);
      this.model.resetPasswordCode = '';

      this.route.queryParams.subscribe(params => {
          this.model.resetPasswordCode = params['resetPasswordCode'];
      });

      this.authService.setNewPassword(this.model).subscribe(next => {
        this.router.navigate(['/']);
        this.alertify.message('Your password has successfully been changed');
        this.buttonJustPressed = false;
      }, error => {
        if (error.status === 400) {
          this.alertify.message(this.translate.translateSingle('Please choose a stronger password.'));
        } else {
          this.alertify.message(this.translate.translateSingle('Your password could not be reset.'));
        }
        this.buttonJustPressed = false;
      });
    }
  }

}
