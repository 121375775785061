import { Component, OnInit } from '@angular/core';
import { LogService } from '../_services/log.service';

@Component({
  selector: 'app-teaser',
  templateUrl: './teaser.component.html',
  styleUrls: ['./teaser.component.css']
})
export class TeaserComponent implements OnInit {

  constructor(private logger: LogService) { }

  ngOnInit() {
    this.logger.logEntry('Teaser');
  }

}
