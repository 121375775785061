import { Component, OnInit, AfterViewInit, EventEmitter, Directive, HostListener, ElementRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { AlertifyService } from '../_services/alertify.service';
import { MessengerService } from '../_services/messenger.service';
import { Message } from '../_models/message';
import { LogService } from '../_services/log.service';
import { TranslateService } from '../_services/translate.service';
import { trigger, transition, state, animate, style, AnimationEvent, keyframes } from '@angular/animations';


@Component({
  selector: 'app-home',
  animations: [
    trigger('outIn', [
      state('out', style({
        width: '100vw',
        opacity: 0,
        'font-size': '1vw',
      })),
      state('in', style({
        width: '100vw',
        opacity: 1,
        'font-size': '2.6vw',
      })),
      transition('in => out', [
        animate('1s 1200ms ease-in')
      ]),
      transition('out => in', [
        animate('1s 1200ms ease-out')
      ]),
    ]),
    trigger('outInSmall', [
      state('outSmall', style({
        width: '58vw',
        opacity: 0,
        'font-size': '2.5vw',
      })),
      state('inSmall', style({
        width: '80vw',
        opacity: 1,
        'font-size': '5vw',
      })),
      transition('inSmall => outSmall', [
        animate('1s 1200ms ease-in')
      ]),
      transition('outSmall => inSmall', [
        animate('1s 1200ms ease-out')
      ]),
    ]),
    trigger('onAppearRow1', [
      state('hidden', style({
        opacity: 0,
      })),
      state('visible', style({
        opacity: 1,
      })),
      state('hiddenR2', style({
        opacity: 0,
      })),
      state('visibleR2', style({
        opacity: 1,
      })),
      transition('hidden => visible', [
        animate('1s 1200ms ease-in')
      ]),
      transition('visible => hidden', [
        animate('1s 1200ms ease-in')
      ]),
      transition('hiddenR2 => visibleR2', [
        animate('1s 2200ms ease-in')
      ]),
      transition('visibleR2 => hiddenR2', [
        animate('1s 2200ms ease-in')
      ]),
    ]),
  ],
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
  isIn = false;

  title = 'Phree - Mobile Energy';
  
  message: Message;
  lat: Number = 47.4019752;
  lng: Number = 8.5430129;

  sendMessageForm: FormGroup;

  constructor( private router: Router,
    private alertify: AlertifyService,
    private fb: FormBuilder, private messenger: MessengerService,
    private route: ActivatedRoute, private logger: LogService,
    private translate: TranslateService,
    public el: ElementRef,
    private titleService: Title,
    private meta: Meta) {

      // make bookmarks work
      router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            const tree = router.parseUrl(router.url);
            if (tree.fragment) {
              const element = document.querySelector('#' + tree.fragment);
              if (element) { element.scrollIntoView(); }
            }
         }
      });
    }

  public icon = {
    url: '/../../assets/images/marker_red.png',
    scaledSize: {
      height: 40,
      width: 30
    }
  };

  ngOnInit() {
    this.createSendMessageForm();
    this.logger.logEntry('Home');
    this.titleService.setTitle(this.title);

    this.meta.addTag({name: 'keywords', content: 'Charge on the go, Rent a Power bank'});
    this.meta.addTag({name: 'description', content: 'Running out of battery? Get a power bank now, charge on the go - 30 Minutes for Phree!'});
    this.meta.addTag({name: 'author', content: 'Phree Mobile Solutions'});
    this.meta.addTag({name: 'robots', content: 'index, follow'});

    
    this.meta.addTag({property: 'og:site_name', content: 'Phree - Mobile Energy'});
    this.meta.addTag({property: 'og:title', content: 'Phree - Mobile Energy'});
    this.meta.addTag({property: 'og:url', content: 'https://phree.ch'});
    this.meta.addTag({property: 'og:type', content: 'website'});
    this.meta.addTag({property: 'og:image', content: 'https://s3.eu-central-1.amazonaws.com/phreemobilesolutions.website/auswahl_zugeschnitten.jpg'});
    this.meta.addTag({property: 'og:image:width', content: '655'});
    this.meta.addTag({property: 'og:image:height', content: '492'});
  }

  ngAfterViewInit() {
    this.isIn = true;
  }

  createSendMessageForm() {
    this.sendMessageForm = this.fb.group({
      fromname: ['', Validators.required],
      fromemail: ['', Validators.required],
      message: ['', Validators.required]
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: FormGroup) {
    return null;
    // return g.get('password').value === g.get('confirmPassword').value ? null : {'mismatch': true};
  }

  sendMessage() {
    if (this.sendMessageForm.valid) {
      this.message = Object.assign({}, this.sendMessageForm.value);
      this.messenger.sendMessage(this.message).subscribe(next => {
        this.alertify.message(this.translate.translateSingle('Thanks for contacting us!'));
      }, error => {
        if (error.status === 501) {
          this.alertify.message(this.translate.translateSingle('Message could not be sent. Please try again later.'));
        }
      });
    }
  }

  toggle() {
    this.isIn = !this.isIn;
  }
}
