import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/user';
import { map } from 'rxjs/operators';
import { Powerhost } from '../_models/powerhost';

@Injectable({
  providedIn: 'root'
})
export class PowerhostService {
  baseUrl = environment.apiUrl + 'powerhosts/';

  constructor(private http: HttpClient) {}

  getPowerhosts(): Observable<Powerhost[]>  {
    return this.http.get<Powerhost[]>(this.baseUrl);
  }

}
