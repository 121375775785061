import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../_services/translate.service';

@Component({
  selector: 'app-registrationfailure',
  templateUrl: './registrationfailure.component.html',
  styleUrls: ['./registrationfailure.component.css']
})
export class RegistrationfailureComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

}
