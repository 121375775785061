import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { AlertifyService } from '../_services/alertify.service';
import { MessengerService } from '../_services/messenger.service';
import { LogService } from '../_services/log.service';
import { TranslateService } from '../_services/translate.service';
import { AgmMarker } from '@agm/core';
import { PowerhostService } from '../_services/powerhost.service';
import { Powerhost, Powerhoststatus } from '../_models/powerhost';
import { Address } from '../_models/address';
import { trigger, transition, animate, style } from '@angular/animations';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-powerhosts',
  templateUrl: './powerhosts.component.html',
  styleUrls: ['./powerhosts.component.css'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})
export class PowerhostsComponent implements OnInit {
  title = 'Phree - ';
  lat: Number = 47.4019752;
  lng: Number = 8.5430129;
  visible: Boolean = false;
  photoUrl: string;
  // photoBaseUrl: 'https://api.phree.ch/powerhosts/';
  photoBaseUrl = environment.apiUrl + 'powerhosts/';
  label: string;
  powerbanksAvailable: string;
  powerbanksRetournable: string;

  agmMarkers: AgmMarker[];
  addr: Address = {
    address1: '',
    city: '',
    postalcode: ''
  };

  powerhosts: Powerhost[] = [{
    label: '',
    latitude: 0,
    longitude: 0,
    id: 0,
    powerHostStatus: Powerhoststatus.ok,
    address: this.addr
  }];

  public iconRed = {
    url: '/../../assets/images/marker_red.png',
    scaledSize: {
      height: 40,
      width: 30
    }
  };

  public iconBlack = {
    url: '/../../assets/images/marker_black.png',
    scaledSize: {
      height: 40,
      width: 30
    }
  };

  public iconBlue = {
    url: '/../../assets/images/marker_blue.png',
    scaledSize: {
      height: 40,
      width: 30
    }
  };

  public iconGrey = {
    url: '/../../assets/images/marker_grey.png',
    scaledSize: {
      height: 40,
      width: 30
    }
  };

  constructor( private router: Router, private alertify: AlertifyService,
    private fb: FormBuilder, private messenger: MessengerService,
    private route: ActivatedRoute, private logger: LogService,
    public translate: TranslateService,
    private powerhostService: PowerhostService,
    private titleService: Title) { }

  ngOnInit() {
    this.titleService.setTitle(this.title + this.translate.translateSingle('Venues'));
    this.powerhostService.getPowerhosts().subscribe( result => {
      this.powerhosts = result;
      // result.forEach(powerhost => {

      //   this.agmMarkers.push( {
      //     latitude: powerhost.latitude,
      //     longitude: powerhost.longitude,
      //     iconUrl: this.icon });
      //   const marker: AgmMarker({latitude: 100, longitude: }); // = new AgmMarker(new _markerManger());
      //   marker.latitude = 43;
      // });
    });
  }

  getIcon(powerhost: Powerhost) {
    if (powerhost.powerHostStatus === Powerhoststatus.ok) {
      return this.iconRed;
    }
    if (powerhost.powerHostStatus === Powerhoststatus.noBorrow) {
      return this.iconBlack;
    }
    if (powerhost.powerHostStatus === Powerhoststatus.noReturn) {
      return this.iconBlue;
    }
    if (powerhost.powerHostStatus === Powerhoststatus.noBorrowNoReturn) {
      return this.iconGrey;
    }
  }

  go2Powerhost(powerhost: Powerhost) {
    this.visible = true;
    this.label = powerhost.label;
    this.photoUrl = this.photoBaseUrl  + powerhost.id + '/image';

    this.powerbanksAvailable = this.translate.translateSingle('YES');
    this.powerbanksRetournable = this.translate.translateSingle('YES');

    if (powerhost.powerHostStatus === Powerhoststatus.ok) {
      this.powerbanksAvailable = this.translate.translateSingle('YES');
      this.powerbanksRetournable = this.translate.translateSingle('YES');
    }
    if (powerhost.powerHostStatus === Powerhoststatus.noReturn) {
      this.powerbanksAvailable = this.translate.translateSingle('YES');
      this.powerbanksRetournable = this.translate.translateSingle('NO');
    }
    if (powerhost.powerHostStatus === Powerhoststatus.noBorrow) {
      this.powerbanksAvailable = this.translate.translateSingle('NO');
      this.powerbanksRetournable = this.translate.translateSingle('YES');
    }
    if (powerhost.powerHostStatus === Powerhoststatus.noBorrowNoReturn) {
      this.powerbanksAvailable = this.translate.translateSingle('NO');
      this.powerbanksRetournable = this.translate.translateSingle('NO');
    }

    console.log(powerhost);
  }

  closePowerhostDetail() {
    this.visible = false;
  }
}
