import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { Message } from '../_models/message';
import { environment } from '../../environments/environment';
import { LogEntry } from '../_models/logEntry';


@Injectable({
  providedIn: 'root'
})
export class LogService {
  baseUrl = environment.apiUrl + 'log/';

  constructor(private http: HttpClient) {}

  logEntry(_site: string) {

    const entry: LogEntry = {
      site: _site,
      useragent: window.navigator.userAgent,
      browser: window.navigator.appCodeName,
      language: window.navigator.language,
      languages: window.navigator.languages.toString(),
      system: window.navigator.platform,
      os: window.navigator.vendor,
      screenwidth: window.screen.width,
      screenheight: window.screen.height,
      hash: window.location.hash
    };

    return this.http.post(this.baseUrl, entry).subscribe(next => {
    }, error => {
      if (error.status === 501) {
        console.log('Message could not be sent. Please try again later.');
      }
    });
  }
}
