import { Component, OnInit } from '@angular/core';
import { LogService } from '../_services/log.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '../_services/translate.service';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.css']
})
export class TeamComponent implements OnInit {

  lat: Number = 47.4019752;
  lng: Number = 8.5430129;

  constructor(private router: Router, private logger: LogService, private translate: TranslateService) {
    // make bookmarks work
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
          const tree = router.parseUrl(router.url);
          if (tree.fragment) {
            const element = document.querySelector('#' + tree.fragment);
            if (element) { element.scrollIntoView(); }
          }
       }
    });
   }

  public icon = {
    url: '/../../assets/images/marker_red.png',
    scaledSize: {
      height: 40,
      width: 30
    }
  };

  ngOnInit() {
    this.logger.logEntry('TEAM');
  }

}
