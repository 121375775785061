import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { validateConfig } from '@angular/router/src/config';
import { variable } from '@angular/compiler/src/output/output_ast';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + 'auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;

  constructor(private http: HttpClient) { }

  login(model: any) {
    return this.http.post(this.baseUrl + 'login', model).pipe(
      map((response: any) => {
        const user = response;
        if (user) {
          localStorage.setItem('token', user.token);
          this.decodedToken = this.jwtHelper.decodeToken(user.token);
        }
      })
    );
  }

  signUp(model: any) {
    return this.http.post(this.baseUrl + 'register', model);
  }
  
  confirmPhone(model: any) {
    return this.http.post(this.baseUrl + 'ConfirmPhonenumber', model, { responseType: 'text' });
  }

  resetPassword(model: any) {
    return this.http.post(this.baseUrl + 'resetpasswordrequest', model);
  }

  setNewPassword(model: any) {
    return this.http.post(this.baseUrl + 'ResetPassword', model);
  }

  loggedIn() {
    const token = localStorage.getItem('token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  getPasswordrequirements() {
    let lang = localStorage.getItem('language');

    if (lang == null) {
      lang = 'en-GB';
      if (navigator.language.includes('de')) {
        lang = 'de-CH';
      }
      if (navigator.language.includes('fr')) {
        lang = 'fr-CH';
      }
      if (navigator.language.includes('it')) {
        lang = 'it-CH';
      }
    }
    return this.http.get(this.baseUrl + 'getPasswordrequirements/' + lang, {responseType: 'text'});
  }
}
