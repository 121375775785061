import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { TranslateService } from '../_services/translate.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  year: number;
  constructor(private router: Router, private translate: TranslateService) {}

  ngOnInit() {
    this.year = (new Date()).getFullYear();
  }

  goToTop(): void {
    window.scroll(0, 0);
  }
}
