export class RentalFee {
    rentalFeePerHour_CHF: number;
    freeRentalPeriod_min: number;
    maxRentalPeriod_days: number;
    oneTimeCostAfterFreePeriod_CHF: number;
    maximumRentalPrice_CHF: number;
    buyingPrice_CHF: number;
    feeForReturningOnDifferentPowerHost_CHF: number;
    discountRelative_CHF: number;
    discountAbsolute_CHF: number;
    validFrom: Date;
    validTill: null;
}