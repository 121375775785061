import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TranslateService } from '../_services/translate.service';
import { User } from '../_models/user';
import { UserService } from '../_services/user.service';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap';
import { Sex } from '../_models/sex';


@Component({
  selector: 'app-userdetails',
  templateUrl: './userDetails.component.html',
  styleUrls: ['./userDetails.component.css']
})
export class UserDetailsComponent implements OnInit {
  Sex = Sex;
  model: any = {};
  user: User;
  Form: FormGroup;
  buttonJustPressed: Boolean = false;
  language: String;
  bsConfig: Partial<BsDatepickerConfig>;
  bsValue = new Date();

  languages = ['Deutsch', 'Français', 'Italiano', 'English'];
  SexValues = Object.values(Sex).filter( e => typeof( e ) === 'number' );

  constructor(private authService: AuthService, private alertify: AlertifyService,
    private router: Router, private fb: FormBuilder,
    private route: ActivatedRoute, private translate: TranslateService,
    private userService: UserService,
    private localeService: BsLocaleService) {

      this.localeService.use('de');
     }

  ngOnInit() {
    this.bsConfig = {
      containerClass: 'theme-default'
      // dateInputFormat: 'YYYY-MM-DD'
    };

    this.user = new User();
    this.createFormWithModel(this.user);

    this.setUser();
  }

  createFormWithModel(user: User) {
    // this.Form = this.fb.group(user);
    this.Form = this.fb.group({
      sex: [user.sex, Validators.required],
      firstname: [user.firstname, Validators.required],
      surname: [user.surname, Validators.required],
      dateOfBirth: [user.dateOfBirth, Validators.required],
      language: [user.language, Validators.required],
    }, {validator: this.matchValidator});
}

  setUser() {
    this.userService.getUser().subscribe(result => {
      this.user = result;

      this.user.language = 'Deutsch';
      if (this.user.languageStr === 'fr-CH') {
        this.user.language = 'Français';
        this.localeService.use('fr');
      } else if (this.user.languageStr === 'it-CH') {
        this.user.language = 'Italiano';
        this.localeService.use('it');
      } else if (this.user.languageStr === 'en-GB') {
        this.user.language = 'English';
        this.localeService.use('en');
      } else {
        this.localeService.use('de');
      }

      this.user.dateOfBirth = new Date(this.user.dateOfBirth);

      this.createFormWithModel(this.user);
    }, error =>  {
      console.log(error);
    });
  }

  matchValidator(g: FormGroup) {
    return null;
    // return g.get('password').value === g.get('confirmPassword').value ? null : {'mismatch': true};
  }

  submit() {
    // this.user.firstname = this.Form.get('firstname').value;
    // this.user.surname = this.Form.get('surname').value;

    this.user = Object.assign({}, this.Form.value);

    // set languageStr property
    this.user.languageStr = 'de-CH';
    if (this.user.language === 'Français') {
      this.user.languageStr = 'fr-CH';
    } else if (this.user.language === 'Italiano') {
      this.user.languageStr = 'it-CH';
    } else if (this.user.language === 'English') {
      this.user.languageStr = 'en-GB';
    }
    this.translate.use(this.user.languageStr.toString()).then(() => {
      this.translate.save(this.user.languageStr.toString());
    });

    console.log('submitting changes');
    console.log(this.user);

    this.userService.updateUser(this.user).subscribe(result => {
      console.log(result);
      this.alertify.message(this.translate.translateSingle('Your information has been saved'));
      this.router.navigate(['/']);
    }, error => {
      console.log(error);
      this.alertify.error(this.translate.translateSingle('Your information could not be saved'));
    });
  }

}
