import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { HttpClient, } from '@angular/common/http';
import { RentalFee } from '../../_models/RentalFee';
import { environment } from '../../../environments/environment';
import { trigger, transition, state, animate, style, AnimationEvent, keyframes } from '@angular/animations';
import { TranslateService } from '../../_services/translate.service';

@Component({
  selector: 'app-offer',
  animations: [
    trigger('outIn', [
      state('out', style({
        'max-height': '130%',
        opacity: 0
      })),
      state('in', style({
        'max-width': '100%',
        opacity: 1
      })),
      transition('out => in', [
        animate('1.1s 1500ms ease-out')
      ]),
    ])
  ],
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.css']
})
export class OfferComponent implements OnInit {
  rentalFee: RentalFee;
  baseUrl = environment.apiUrl;
  isIn = false;

  constructor(public el: ElementRef, private translate: TranslateService, private http: HttpClient) {
    this.rentalFee = new RentalFee();
  }

  ngOnInit() {
    this.getPricing();
  }

  getPricing() {
    console.log('getting pricing');
    this.http.get<RentalFee>(this.baseUrl + 'rental/PricingAnonymous/').subscribe(result => {
      this.rentalFee = result;
      console.log(this.rentalFee);
    });
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop;
    const scrollPosition = window.pageYOffset;

    if (scrollPosition >= componentPosition) {
      this.isIn = true;
    } else {
      this.isIn = false;
    }
  }
}
