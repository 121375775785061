import { Address } from './address';
import { Sex } from './sex';

export class User {
  id: number;
  firstname: String = '';
  surname: String = '';
  sex: Sex = Sex.Unknown;
  dateOfBirth: Date;
  email: String;
  language: String = 'Deutsch';
  languageStr: String = 'de-CH';

  phone?: String;
  address: Address;
}
