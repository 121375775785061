import { Injectable } from '@angular/core';
import { HttpClient } from '../../../node_modules/@angular/common/http';
import { Message } from '../_models/message';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MessengerService {
  baseUrl = environment.apiUrl + 'messenger/';

  constructor(private http: HttpClient) {}

  sendMessage(msg: Message) {
    return this.http.post(this.baseUrl + 'send', msg);
  }
}
