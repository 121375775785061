import { Component, OnInit } from '@angular/core';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '../_services/translate.service';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetPassword.component.html',
  styleUrls: ['./resetPassword.component.css']
})
export class ResetPasswordComponent implements OnInit {
  model: any = {};
  resetPasswordForm: FormGroup;
  buttonJustPressed: Boolean = false;

  constructor(private authService: AuthService, private alertify: AlertifyService,
    private fb: FormBuilder, private router: Router, private translate: TranslateService) { }

  ngOnInit() {
    this.createResetPasswordForm();
  }

  createResetPasswordForm() {
    this.resetPasswordForm = this.fb.group({
      email: ['', Validators.required],
    }, {validator: this.validator});
  }

  validator(g: FormGroup) {
    return null;
    // return g.get('password').value === g.get('confirmPassword').value ? null : {'mismatch': true};
  }

  resetPassword() {
    this.buttonJustPressed = true;
    if (this.resetPasswordForm.valid) {
      this.model = Object.assign({}, this.resetPasswordForm.value);
      this.authService.resetPassword(this.model).subscribe(next => {
        this.alertify.message(this.translate.translateSingle('We have sent you an email.'));
        this.router.navigate(['/']);
        this.buttonJustPressed = false;
      }, error => {
        if (error.status === 400) {
          this.alertify.message(
            this.translate.translateSingle(
              // tslint:disable-next-line:quotemark
              "Please check the entered email adddress. We couldn't find an account with that email address."));
        } else {
          this.alertify.message(this.translate.translateSingle('Reseting password failed'));
        }
        this.buttonJustPressed = false;
      });
    }
  }

}
