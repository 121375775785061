import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { BsDropdownModule, TabsModule, BsDatepickerModule, PaginationModule, ButtonsModule } from 'ngx-bootstrap';
import { RouterModule } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import { AgmCoreModule } from '@agm/core';
import { TimeAgoPipe } from 'time-ago-pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule, MatAccordion } from '@angular/material';

import { AppComponent } from './app.component';
import { NavComponent } from './nav/nav.component';
import { FooterComponent } from './footer/footer.component';
import { AuthService } from './_services/auth.service';
import { MessengerService } from './_services/messenger.service';
// import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { AlertifyService } from './_services/alertify.service';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';
import { appRoutes } from './routes';
import { AuthGuard } from './_guards/auth.guard';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { SetNewPasswordComponent } from './setNewPassword/setNewPassword.component';
import { SuccessfullyregisteredComponent } from './successfullyregistered/successfullyregistered.component';
import { RegistrationfailureComponent } from './registrationfailure/registrationfailure.component';
import { SbbComponent } from './sbb/sbb.component';
import { LogService } from './_services/log.service';
import { TranslateService } from './_services/translate.service';
import { TranslatePipe } from './_pipes/translate.pipe';
import { UserDetailsComponent } from './userDetails/userDetails.component';
import { environment } from '../environments/environment';
import { PowerhostsComponent } from './powerhosts/powerhosts.component';
import { AgbComponent } from './agb/agb.component';
import { ConceptComponent } from './concept/concept.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { AboutComponent } from './about/about.component';
import { SupportComponent } from './support/support.component';
import { HowItWorksComponent } from './home/howItWorks/howItWorks.component';
import { OfferComponent } from './home/offer/offer.component';
import { UserExperienceComponent } from './user-experience/user-experience.component';
import { TeaserComponent } from './teaser/teaser.component';

export function tokenGetter() {
  return localStorage.getItem('token');
}

export function setupTranslateFactory(
  service: TranslateService): Function {
    return () => service.use('de-CH');
}

@NgModule({
   declarations: [
      AppComponent,
      NavComponent,
      FooterComponent,
      HomeComponent,
      TeamComponent,
      ResetPasswordComponent,
      SetNewPasswordComponent,
      SuccessfullyregisteredComponent,
      RegistrationfailureComponent,
      SbbComponent,
      TimeAgoPipe,
      TranslatePipe,
      UserDetailsComponent,
      PowerhostsComponent,
      AgbComponent,
      ConceptComponent,
      PrivacyComponent,
      AboutComponent,
      SupportComponent,
      HowItWorksComponent,
      OfferComponent,
      UserExperienceComponent,
      TeaserComponent
   ],
   imports: [
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      FormsModule,
      ReactiveFormsModule,
      BsDropdownModule.forRoot(),
      BsDatepickerModule.forRoot(),
      RouterModule.forRoot(appRoutes),
      AgmCoreModule.forRoot({apiKey: 'AIzaSyCaq7OWDwU-lbad1a3qWN6w_ZcN2X9A5vY'}),
      JwtModule.forRoot({
        config: {
          tokenGetter: tokenGetter,
          whitelistedDomains: ['api.phree.ch'],
          blacklistedRoutes: [environment.apiUrl + 'auth/']
        }
      }),
      MatExpansionModule,
      // MatAccordion,
   ],
   providers: [
      Title,
      AuthService,
      AlertifyService,
      AuthGuard,
      MessengerService,
      LogService,
      TranslateService,
      {
        provide: APP_INITIALIZER,
        useFactory: setupTranslateFactory,
        deps: [
        TranslateService
        ],
        multi: true
      }
   ],
   bootstrap: [
      AppComponent
   ]
})
export class AppModule { }
