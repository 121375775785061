import { Component, OnInit, ElementRef, HostListener } from '@angular/core';
import { trigger, transition, state, animate, style, AnimationEvent, keyframes } from '@angular/animations';

import { TranslateService } from '../../_services/translate.service';
import { LogService } from '../../_services/log.service';

@Component({
  selector: 'app-howItWorks',
  animations: [
     trigger('onAppearRow1', [
      state('hidden', style({
        opacity: 0,
      })),
      state('visible', style({
        opacity: 1,
      })),
      state('hiddenR2', style({
        opacity: 0,
      })),
      state('visibleR2', style({
        opacity: 1,
      })),
      state('hiddenR3', style({
        opacity: 0,
      })),
      state('visibleR3', style({
        opacity: 1,
      })),
      state('hiddenR4', style({
        opacity: 0,
      })),
      state('visibleR4', style({
        opacity: 1,
      })),
      transition('hidden => visible', [
        animate('1s 200ms ease-in')
      ]),
      transition('visible => hidden', [
        animate('0s 0ms ease-in')
      ]),
      transition('hiddenR2 => visibleR2', [
        animate('1s 800ms ease-in')
      ]),
      transition('visibleR2 => hiddenR2', [
        animate('0s 0ms ease-in')
      ]),
      transition('hiddenR3 => visibleR3', [
        animate('1s 1400ms ease-in')
      ]),
      transition('visibleR3 => hiddenR3', [
        animate('0s 0ms ease-in')
      ]),
      transition('hiddenR4 => visibleR4', [
        animate('1s 2000ms ease-in')
      ]),
      transition('visibleR4 => hiddenR4', [
        animate('0s 0ms ease-in')
      ]),
    ]),
  ],
  templateUrl: './howItWorks.component.html',
  styleUrls: ['./howItWorks.component.css']
})
export class HowItWorksComponent implements OnInit {
  isVisible = false;

  constructor(public el: ElementRef) { }

  ngOnInit() {
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll() {
    const componentPosition = this.el.nativeElement.offsetTop
    const scrollPosition = window.pageYOffset

    if (scrollPosition >= componentPosition) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
  }
}
