import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { TeamComponent } from './team/team.component';

import { AuthGuard } from './_guards/auth.guard';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { SetNewPasswordComponent } from './setNewPassword/setNewPassword.component';
import { SuccessfullyregisteredComponent } from './successfullyregistered/successfullyregistered.component';
import { RegistrationfailureComponent } from './registrationfailure/registrationfailure.component';
import { SbbComponent } from './sbb/sbb.component';
import { UserDetailsComponent } from './userDetails/userDetails.component';
import { PowerhostsComponent } from './powerhosts/powerhosts.component';
import { AgbComponent } from './agb/agb.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { ConceptComponent } from './concept/concept.component';
import { AboutComponent } from './about/about.component';
import { SupportComponent } from './support/support.component';
import { UserExperienceComponent } from './user-experience/user-experience.component';
import { TeaserComponent } from './teaser/teaser.component';

export const appRoutes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'login', component: HomeComponent},
    {path: 'SuccessfullyConfirmedInstallation.html', redirectTo: 'SuccessfullyRegistered', pathMatch: 'full'},
    {path: 'SuccessfullyRegistered', component: SuccessfullyregisteredComponent},
    {path: 'FailedConfirmInstallation.html', redirectTo: 'RegistrationFailure', pathMatch: 'full'},
    {path: 'RegistrationFailure', component: RegistrationfailureComponent},
    {path: 'about', component: AboutComponent},
    {path: 'support', component: SupportComponent},
    {
        path: 'team',
        children: [
            {path: '**', component: TeamComponent}
        ]
    },
    {path: 'resetpassword', component: ResetPasswordComponent},
    {
        path: 'setNewPassword',
        children: [
            {path: '**', component: SetNewPasswordComponent}
        ]
    },
    {path: 'venues', component: PowerhostsComponent},
    {path: 'agb', component: AgbComponent},
    {path: 'privacy', component: PrivacyComponent},
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
            {path: 'userdetails', component: UserDetailsComponent},
        ]
    },
    {path: 'sbb/video', redirectTo: 'sbb', pathMatch: 'full'},
    {path: 'sbb', component: SbbComponent},
    {path: 'concept', component: ConceptComponent},
    {path: 'userexperience', component: UserExperienceComponent},
    {path: 'userExperience', component: UserExperienceComponent},
    {path: 'UserExperience', component: UserExperienceComponent},
    {path: 'USEREXPERIENCE', component: UserExperienceComponent},
    {path: 'user-experience', component: UserExperienceComponent},
    {path: 'teaser', component: TeaserComponent},
    {path: 'Teaser', component: TeaserComponent},
    {path: 'TEASER', component: TeaserComponent},
    {path: '**', redirectTo: '', pathMatch: 'full'},
];
