import { Component, OnInit } from '@angular/core';
import { TranslateService } from '../_services/translate.service';

@Component({
  selector: 'app-successfullyregistered',
  templateUrl: './successfullyregistered.component.html',
  styleUrls: ['./successfullyregistered.component.css']
})
export class SuccessfullyregisteredComponent implements OnInit {

  constructor(private translate: TranslateService) { }

  ngOnInit() {
  }

}
