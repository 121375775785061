import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { LogService } from '../_services/log.service';

@Component({
  selector: 'app-concept',
  templateUrl: './concept.component.html',
  styleUrls: ['./concept.component.css']
})
export class ConceptComponent implements OnInit {

  title = 'Phree - Concept';
  constructor(private logger: LogService, private titleService: Title, private meta: Meta) { }

  ngOnInit() {
    this.logger.logEntry('Concept');
    this.titleService.setTitle(this.title);

    this.meta.updateTag({property: 'og:site_name', content: 'Phree - Concept'});
    this.meta.updateTag({property: 'og:title', content: 'Phree - Concept'});
    this.meta.updateTag({property: 'og:url', content: 'https://phree.ch'});
    this.meta.updateTag({property: 'og:type', content: 'website'});
    this.meta.updateTag({property: 'og:image', content: 'https://phree.ch/assets/images/ogimage.png'});
    // this.meta.updateTag({property: 'og:image:width', content: '655'});
    // this.meta.updateTag({property: 'og:image:height', content: '492'});
  }
}
